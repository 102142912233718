import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/';

@Injectable({ providedIn: 'root' })
export class PartnerSpaceService {

  successForVlan: BehaviorSubject<any>;
  successForGateway: BehaviorSubject<any>;
  successForaddGroupAll: BehaviorSubject<any>;
  successForDelegateIp: BehaviorSubject<any>;
  successForIpAll: BehaviorSubject<any>;
  spaceParentId: BehaviorSubject<any>;
  spaceData: BehaviorSubject<any>;
  mainId = this.storageService.getCipherObj('mainId');

  constructor(private http: HttpClient, private storageService: StorageService) {
    this.successForaddGroupAll = new BehaviorSubject<any>(null);
    this.successForDelegateIp = new BehaviorSubject<any>(null);
    this.successForVlan = new BehaviorSubject<any>(null);
    this.successForGateway = new BehaviorSubject<any>(null);
    this.successForIpAll = new BehaviorSubject<any>(null);
    this.spaceParentId = new BehaviorSubject<any>(null);
    this.spaceData = new BehaviorSubject<any>(null);
  }

  get successForaddGroupAll$() {
    return this.successForaddGroupAll.asObservable();
  }
  get successForDelegateIp$() {
    return this.successForDelegateIp.asObservable();
  }
  get successForVlan$() {
    return this.successForVlan.asObservable();
  }
  get successForGateway$() {
    return this.successForGateway.asObservable();
  }
  get successForIpAll$() {
    return this.successForIpAll.asObservable();
  }

  get spaceParentId$() {
    if (this.storageService.getCipherObj('spaceKey') !== null) this.spaceParentId.next({ parentId: this.storageService.getCipherObj('spaceKey') });
    return this.spaceParentId.asObservable();
  }

  get spaceData$() {
    if (this.storageService.getCipherObj('spaceName') !== null) this.spaceData.next({ spaceName: this.storageService.getCipherObj('spaceName') });
    return this.spaceData.asObservable();
  }

  setSpaceParentId(val: any) {
    this.storageService.setCipherObj('spaceKey', val);
    this.spaceParentId.next({ parentId: val });
  }

  setSpaceData(val: any) {
    this.storageService.setCipherObj('spaceName', val.name);
    this.spaceData.next({ spaceName: val.name });
  }

  getResponse(url: any, methodType?: any) {
    return this.http.post<any>(`${BACKEND_URL}dynamicApisdwan/${this.mainId}`, { url, method: methodType })
  }

  addSpace(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  editSpace(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  getSpace(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  getIps(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  generalSpaceApi(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  addVlanData(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`, body)
  }

  deleteData(body: any) {
    return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartyDelete/${this.mainId}`, body)
  }

  getCloudFirewallRecord(spaceId: any) {
    return this.http.get<any>(`${BACKEND_URL}sdwan/firewallHosted/view/${this.mainId}/${spaceId}`)
  }

  updateCloudFirewallRecord(body: any) {
    return this.http.post<any>(`${BACKEND_URL}sdwan/firewallHosted/add/${this.mainId}`, body)
  }

  deleteCloudFirewallRecord(spaceId: any) {
    return this.http.delete<any>(`${BACKEND_URL}sdwan/firewallHosted/delete/${this.mainId}/${spaceId}`)
  }
  //  deleteVlanData(body:any){
  //   return this.http.post<any>(`${BACKEND_URL}addDynamicDataForThirdPartySpace/${this.mainId}`,body)
  //  }
}
