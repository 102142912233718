import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL_VERSION = environment.apiUrl + '/adminPanel/version/';

@Injectable({ providedIn: 'root' })
export class VersionService {

    openVersion: BehaviorSubject<any> = new BehaviorSubject<any>(false);

    constructor(private http: HttpClient, private storageService: StorageService) { }

    get openVersionModal$() {
        return this.openVersion.asObservable();
    }

    getVersionList(search: string,
        page: string,
        limit: number,
        name: string,
        sort: any) {
        let params = new HttpParams()
            .set('searchText', search ? search : '')
            .set('page', page)
            .set('pageSize', limit)
            .set('name', name ? name : 'createdAt')
            .set('sort', sort ? sort : -1)
        return this.http.get(BACKEND_URL_VERSION + '', { params });
    }

    getActiveVersion() {
        return this.http.get(BACKEND_URL_VERSION + 'get/active');
    }

    createVersion(payload: any) {
        return this.http.post(BACKEND_URL_VERSION + '', payload);
    }

    updateVersion(id: string, payload: any) {
        return this.http.put(BACKEND_URL_VERSION + id, payload);
    }

    deleteVersion(id: string) {
        return this.http.delete(BACKEND_URL_VERSION + id);
    }

    updateLoginVersion(version: any) {
        let partnerId = this.storageService.getCipherObj('mainId');
        if (!version || !partnerId) return;
        return this.http.post(environment.apiUrl + '/partnerPanel/updateVersion/' + partnerId, { version });
    }

}
