import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + "/partnerPanel/permission";

@Injectable({ providedIn: 'root' })
export class PartnerGroupPermissionService {

  mainId = this.storageService.getCipherObj('mainId');
  loginId = this.storageService.getCipherObj('ID');

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getAllGroups(search: string,
    page: string,
    limit: number,
    name: any,
    sort: any
  ) {
    let params = new HttpParams()
      .set('fname', search ? search : '')
      .set('email', search ? search : '').
      set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    // .set('page', page)
    // .set('pageSize', limit)
    let loginId = this.storageService.getCipherObj('ID');
    return this.http.get<any>(BACKEND_URL + "/list/" + this.mainId + "/" + loginId, { params });
  }

  getAllOrgUsers() {
    let url = environment.apiUrl + `/partnerPanel/user/orgUserList/${this.mainId}`;
    return this.http.get<any>(url);
  }

  addNewGroup(body: any) {
    return this.http.post<any>(BACKEND_URL + "/add/" + this.mainId + "/", body);
  }

  getAllPermissions() {
    return this.http.get<any>(BACKEND_URL + "/permission/" + this.mainId + "/",);
  }

  editGroup(id: any, body: any) {
    return this.http.put<any>(BACKEND_URL + "/update/" + id + "/" + this.mainId + "/", body);
  }

  deleteGroup(id: any) {
    return this.http.delete<any>(BACKEND_URL + "/delete/" + id + "/" + this.mainId + "/", {});
  }

  getPermissionByGroup(id: any) {
    let url = BACKEND_URL + "/getPermissionByGroup/" + id + "/" + this.mainId + "/";
    return this.http.get<any>(url);
  }
}
