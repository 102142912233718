import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MailConfigModel } from '../models/mail-configuration.model';
import { SecurityConfigModel } from '../models/security-configuration.model';
import { SystemConfigModel } from '../models/system-configuration.model';


const BACKEND_URL = environment.apiUrl + '/adminPanel/';
@Injectable({ providedIn: 'root' })
export class GeneralConfigurationService {

    constructor(private http: HttpClient) { }

    // Fetch Listing Data
    getViewData() {
        return this.http.get<SystemConfigModel>(BACKEND_URL + 'system/view');
    }

    // Create staff 
    createSystem(data: any) {
        return this.http.post<SystemConfigModel>(BACKEND_URL + 'system/add', data);
    }

    // update Partner
    updateSystem(data: any, systemId: string) {
        return this.http.put<SystemConfigModel>(BACKEND_URL + `system/update/${systemId}`, data);
    }

    getIlluminatePlanList() {
        return this.http.get<any>(BACKEND_URL + `system/illuminatePlanList`);
    }

    updateIlluminatePlan(data: any) {
        return this.http.put<any>(BACKEND_URL + `system/updateIlluminatePlan`, data);
    }

    getFirewallList() {
        return this.http.get<any>(BACKEND_URL + `system/firewallList`);
    }

    updateFirewallList(data: any) {
        return this.http.put<any>(BACKEND_URL + `system/updateFirewallList`, data);
    }

    getMaintenanceMode() {
        return this.http.get<MailConfigModel>(BACKEND_URL + 'system/maintenanceMode');
    }

    toggleMaintenanceMode() {
        return this.http.get<MailConfigModel>(BACKEND_URL + 'system/toggleMaintenanceMode', {});
    }

    // Fetch security Data
    getSecurityViewData() {
        return this.http.get<SecurityConfigModel>(BACKEND_URL + 'security/view');
    }

    // Create Security 
    createSecurity(data: any) {
        return this.http.post<SecurityConfigModel>(BACKEND_URL + 'security/add', data);
    }

    // update Security
    updateSecurity(data: any, securityId: string) {
        return this.http.put<SecurityConfigModel>(BACKEND_URL + `security/update/${securityId}`, data);
    }


    // Fetch Mail Data
    getMailViewData() {
        return this.http.get<MailConfigModel>(BACKEND_URL + 'mailConfig/view');
    }

    // Create Mail 
    createMail(data: any) {
        return this.http.post<MailConfigModel>(BACKEND_URL + 'mailConfig/create', data);
    }

    // update Mail
    updateMail(data: any) {
        return this.http.put<MailConfigModel>(BACKEND_URL + `mailConfig/update`, data);
    }

    saveReportMail(mail: any) {
        return this.http.put<MailConfigModel>(BACKEND_URL + `mailConfig/saveReportMail`, { reportEmail: mail ?? '' });
    }

    sendReportEmail() {
        return this.http.get<any>(BACKEND_URL + `mailConfig/sendReportToMail`);
    }

    sendTestMail(mailBody: any) {
        return this.http.post<MailConfigModel>(BACKEND_URL + 'mailConfig/test', mailBody);
    }
}
