import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-illuminate-data-modal',
  templateUrl: './illuminate-data-modal.component.html',
  styleUrls: ['./illuminate-data-modal.component.scss']
})
export class IlluminateDataModalComponent implements OnInit {
  type: number = 1;
  // admin illuminate plan access
  illuminatePlanAccess: any[] = []
  // user illuminate plan list
  selectedPlan: any;
  // partner illuminate details
  illuminateForm: FormGroup;
  illuminateData: any;

  errors: any;
  submitted: any = false;

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private partnerBondingService: PartnerBondingService, private formErrorHandler: FormErrorService,
    public activeModal: NgbActiveModal, private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.type == 3) {
      if (this.illuminateData) this.illuminateForm = this.createForm(this.illuminateData);
      else this.illuminateForm = this.createForm();
    }
    if (this.type == 2) {
      if (this.illuminatePlanAccess && this.illuminatePlanAccess.length > 0) {
        this.selectedPlan = this.illuminatePlanAccess[0].key;
      }
    }
  }

  get f() {
    return this.illuminateForm.controls;
  }

  createForm(data: any = {}) {
    return this.fb.group({
      illuminate_provision_url: [data?.illuminate_provision_url || '', Validators.required],
      illuminate_provision_token: [data?.illuminate_provision_token || '', Validators.required],
    })
  }

  disablePlanAccess() {
    let totalAccessPlan = 0;
    this.illuminatePlanAccess.forEach((item) => {
      if (item && item.access) totalAccessPlan += 1;
    })
    return totalAccessPlan > 1 ? false : true;
  }

  onSave() {
    this.submitted = true;
    if (this.type == 1) {
      this.activeModal.close({ event: 'save', illuminatePlanAccess: this.illuminatePlanAccess });
    } else if (this.type == 2) {
      this.activeModal.close({ event: 'save', plan: this.selectedPlan });
    } else if (this.type == 3) {
      if (this.illuminateForm.invalid) return;
      this.activeModal.close({ event: 'save' });
      this.sharedService.showLoader();
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }

  isControlValid(controlName: string,): boolean {
    return this.formErrorHandler.isControlValid(controlName, this.illuminateForm);
  }

  isControlInvalid(controlName: string): boolean {
    return this.formErrorHandler.isControlInvalid(controlName, this.illuminateForm);
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.illuminateForm);
  }

  isControlTouched(controlName: string): boolean {
    return this.formErrorHandler.isControlTouched(controlName, this.illuminateForm);
  }
}