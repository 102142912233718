import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/';
@Injectable({ providedIn: 'root' })
export class PartnerBondingApiService {
  bondAPIData: BehaviorSubject<any>;
  mainId = this.storageService.getCipherObj('mainId');

  constructor(private http: HttpClient, private storageService: StorageService) {
    this.bondAPIData = new BehaviorSubject<any>(null);
  }

  get bondData$() {
    return this.bondAPIData.asObservable();
  }

  getBondingApi() {
    return this.http.get<any>(`${BACKEND_URL}getBALoginApiDetail/${this.mainId}`)
  }
  createBondingApi(data: any) {
    return this.http.post<any>(`${BACKEND_URL}addBALoginApiDetail/${this.mainId}`, data)
  }
  updateBondingApi(data: any) {
    return this.http.put<any>(`${BACKEND_URL}updateBALoginApiDetail/${this.mainId}`, data)
  }
  testBondingApi(data: any) {
    return this.http.post<any>(`${BACKEND_URL}testBALoginApiDetail/${this.mainId}`, data)
  }
}
