import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PartnerBondViewComponent } from 'src/app/modules/partner/bonds/partner-bond-view/partner-bond-view.component';
import { RhsService } from 'src/app/services/rhs.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { StorageService } from 'src/app/services/storage.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { SharedNetworkConnectedIpEditComponent } from './shared-network-connected-ip-edit/shared-network-connected-ip-edit.component';

const RHS_HOST = environment.rhsHost;

@Component({
  selector: 'app-shared-network-connected-ip',
  templateUrl: './shared-network-connected-ip.component.html',
  styleUrls: ['./shared-network-connected-ip.component.scss']
})
export class SharedNetworkConnectedIPComponent implements OnInit {
  bondPermission: any;
  latest_tuning: any;
  // Nepean Nexus functionality
  CITunnelForm: FormGroup;
  ciTunnelData: any = null;
  tunnelList: any = [];
  targetDateTime: any;
  intervalId: any;
  bondMac: any;
  tunnelExpiry: any = { hour: 0, minutes: 0, seconds: 0 };
  expiryOptionList: any[] = [
    { name: "5 Minutes", value: 5 },
    { name: "30 Minutes", value: 30 },
    { name: "60 Minutes", value: 60 },
    { name: "8 Hours", value: 480 },
  ];
  nepeanNexusPermission: boolean = false;
  ciTunnelEditLoader: boolean = false;
  ciTunnelDeleteLoader: boolean = false;
  isTunnelError: boolean = false;

  @Input() allConnectedIPS: any[] = [];
  @Input() InterfaceDetailArray: any[] = [];
  @Input() bondId: any;
  @Input() bondData: any;
  @Input() permissionsObj: any;

  @Output() getConnectedIPS = new EventEmitter<any>();

  @ViewChild('ciTunnelPopupButton') ciTunnelPopupButton: any;

  constructor(private PartnerBondingService: PartnerBondingService,
    private partnerBondViewComponent: PartnerBondViewComponent,
    private cd: ChangeDetectorRef, private rhsService: RhsService,
    public sharedService: SharedService, private fb: FormBuilder,
    private modalService: NgbModal, private storageService: StorageService,) { }

  ngOnInit(): void {
    this.PartnerBondingService.latestTuningData$.subscribe((data) => {
      this.latest_tuning = data;
      this.cd.detectChanges();
    });
    let data = this.storageService.getCipherObj('bondPermission');
    if (data && data.id == this.bondId) this.bondPermission = data.permission;
    this.cd.detectChanges();
    // this.PartnerBondingService.bond_eth0_mac$.subscribe((data) => {
    //   this.bondMac = data;
    //   this.cd.detectChanges();
    // })
    // this.getNepeanNexusPermission();
    // this.rhsService.tunnelListDataByBond$.subscribe((data) => {
    //   this.tunnelList = data;
    //   this.tunnelList = this.tunnelList.filter((tunnel: any) => tunnel.type == AppConst.tunnelType.ciTunnel);
    //   this.tunnelList.forEach((tunnel: any) => {
    //     if (tunnel.expireSession) {
    //       const startTime = moment(tunnel.expireSession);
    //       const currentTime = moment();
    //       const diff = moment.duration(startTime.diff(currentTime));
    //       if (diff.asMinutes() <= 0) {
    //         this.deleteTunnel(tunnel.ciId);
    //       }
    //     }
    //   })
    //   this.cd.detectChanges();
    // })
    // this.CITunnelForm = this.createCITunnelForm();
  }

  deleteIp(ipData: any, name: any) {
    let questionTitle = 'Are you sure you want to delete this ip?';
    let text = ""
    let confirmButtonText = "Yes, Delete it!"
    let body = {
      method: "DELETE",
      url: `bonds/${this.bondId}/connected_ips/${ipData.id}/`,
      data: {},
      type: "Connected IP",
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      ip: ipData?.ip,
      id: ipData?.id,
      name: name
    };
    this.sharedService.swalQuestion(questionTitle, text, confirmButtonText).then((result) => {
      if (result.isConfirmed) {
        this.sharedService.showLoader();
        this.PartnerBondingService.deleteData(body).subscribe((data: any) => {
          if (data.code == 204 || data.code == 200) this.sharedService.loggerSuccess('connected ip deleted successfully.');
          else this.sharedService.loggerError(JSON.stringify(data?.data));
          this.sharedService.hideLoader();
          this.getConnectedIPS.emit();
        }, (err) => {
          try {
            let error = JSON.parse(err);
            if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
            else this.sharedService.loggerError(err);
            this.sharedService.hideLoader();
            this.getConnectedIPS.emit();
          } catch (e) {
            // JSON parsing failed, assume it's a plain error message
            this.sharedService.hideLoader();
            this.sharedService.loggerError(err);
            this.cd.detectChanges();
          }
        });
      }
    });
  }

  editIp(ipData: any) {
    let modal = this.modalService.open(SharedNetworkConnectedIpEditComponent, { size: 'lg' });
    if (ipData) {
      modal.componentInstance.ipDetail = ipData;
      modal.componentInstance.isEdit = true;
    } else modal.componentInstance.isEdit = false;
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.InterfaceDetailArray = this.InterfaceDetailArray;
    modal.result.then((data) => {
      if (data && data?.event === 'save') this.getConnectedIPS.emit();
    }, () => { });
  }

  getNepeanNexusPermission() {
    let moduleList = this.storageService.getCipherObj('Module_Access');
    if (moduleList && moduleList.length > 0) {
      moduleList.forEach((item: any) => {
        if (item && item.name == AppConst.moduleList.nepeanNexusModule) this.nepeanNexusPermission = true;
      });
    }
  }

  createCITunnelForm(data: any = {}) {
    return this.fb.group({
      ip: [data.ip || "", Validators.required],
      port: [data.port || "", Validators.required],
      expiry: [5, Validators.required],
    });
  }

  getTunnelForCI(ciId: any) {
    let isFound = false;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.ciId == ciId && tunnel.type == AppConst.tunnelType.ciTunnel && tunnel.body.serverPort) isFound = true;
    });
    return isFound;
  }

  openTunnelPopup(ci: any) {
    this.ciTunnelDeleteLoader = false;
    this.ciTunnelEditLoader = false;
    this.CITunnelForm.controls.ip.setValue("");
    this.CITunnelForm.controls.port.setValue("");
    this.CITunnelForm.controls.expiry.setValue(5);
    this.ciTunnelData = null;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.ciId == ci.id) {
        this.ciTunnelData = tunnel;
        this.initializeTimer(tunnel.expireSession, ci);
        this.CITunnelForm.controls.ip.setValue(tunnel.body.ip);
        this.CITunnelForm.controls.port.setValue(tunnel.body.port);
      }
    });
    // if (!this.ciTunnelData) this.CITunnelForm.controls.ip.setValue(ci.dest_nat_ip);
  }

  initializeTimer(startDateTime: string, ci: any): void {
    const startTime = moment(startDateTime);
    const currentTime = moment();
    const diff = moment.duration(startTime.diff(currentTime));
    if (diff.asMinutes() <= 0) {
      this.deleteTunnel(ci.id);
      this.ciTunnelPopupButton.close();
      this.tunnelExpiry = { hour: 0, minutes: 0, seconds: 0 };
      return;
    }
    this.targetDateTime = currentTime.add(diff);
    this.updateTimer(ci);
    this.intervalId = setInterval(() => this.updateTimer(ci), 1000);
  }

  updateTimer(ci: any): void {
    const currentTime = moment();
    const duration = moment.duration(this.targetDateTime.diff(currentTime));
    if (duration.asMilliseconds() <= 0) {
      clearInterval(this.intervalId);
      this.deleteTunnel(ci.id);
      this.ciTunnelPopupButton.close();
      this.tunnelExpiry = { hour: 0, minutes: 0, seconds: 0 };
    } else {
      this.tunnelExpiry = {
        hour: Math.floor(duration.asHours()) < 10 ? `0${Math.floor(duration.asHours())}` : Math.floor(duration.asHours()),
        minutes: Math.floor(duration.minutes()) < 10 ? `0${Math.floor(duration.minutes())}` : Math.floor(duration.minutes()),
        seconds: Math.floor(duration.seconds()) < 10 ? `0${Math.floor(duration.seconds())}` : Math.floor(duration.seconds()),
      };
    }
    this.cd.detectChanges();
  }

  openConnection(ciId: any) {
    if (!ciId) return;
    this.tunnelList.forEach((tunnel: any) => {
      if (tunnel.ciId == ciId && tunnel.type == AppConst.tunnelType.ciTunnel && tunnel.body.serverPort) {
        window.open(`https://${RHS_HOST}:${tunnel.body.serverPort}`, '_blank');
      }
    });
  }

  createUpdateTunnel(data: any) {
    if (!this.bondMac) {
      this.sharedService.loggerError('Unable to detect Mac Address');
      return;
    }
    if (this.CITunnelForm.invalid) {
      this.isTunnelError = true;
      return;
    } this.isTunnelError = false;
    let expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + this.CITunnelForm.controls.expiry.value);
    let reqObject = {
      ip: this.CITunnelForm.controls.ip.value,
      port: this.CITunnelForm.controls.port.value,
      expire: expiryDate,
      ciId: data?.id,
      bondName: this.storageService.getCipherObj('bondName'),
      bondId: this.bondId,
      nodeKey: this.bondData?.bonder?.key,
      type: AppConst.tunnelType.ciTunnel,
      mac: this.bondMac,
    };
    this.ciTunnelEditLoader = true;
    this.rhsService.createUpdateTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.sharedService.loggerSuccess(addRes.message);
        this.partnerBondViewComponent.getRHSTunnelList();
        this.ciTunnelPopupButton.close();
      } else this.sharedService.loggerError(addRes.message);
      this.ciTunnelEditLoader = true;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.ciTunnelEditLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.ciTunnelEditLoader = false;
        this.cd.detectChanges();
      }
    });
  }

  deleteTunnel(ciId: any) {
    if (!ciId) return;
    let reqObject = {
      ciId: ciId,
      bondId: this.bondId,
      type: AppConst.tunnelType.ciTunnel,
    };
    this.ciTunnelDeleteLoader = true;
    this.rhsService.deleteTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.partnerBondViewComponent.getRHSTunnelList();
        this.sharedService.loggerSuccess(addRes.message);
        this.ciTunnelPopupButton.close();
      } else this.sharedService.loggerError(addRes.message);
      this.ciTunnelDeleteLoader = true;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.ciTunnelDeleteLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.ciTunnelDeleteLoader = false;
        this.cd.detectChanges();
      }
    });
  }
}
