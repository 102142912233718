import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MailConfigModel } from '../models/mail-configuration.model';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/mailConfig/';
@Injectable({ providedIn: 'root' })
export class PartnerMailConfigService {
  partnerId = this.storageService.getCipherObj('mainId');

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getMailViewData() {
    return this.http.get<MailConfigModel>(BACKEND_URL + `view/${this.partnerId}`);
  }

  createMail(data: any) {
    return this.http.post<MailConfigModel>(BACKEND_URL + `create/${this.partnerId}`, data);
  }

  updateMail(data: any, id: any) {
    return this.http.put<MailConfigModel>(BACKEND_URL + `update/${id}/${this.partnerId}`, data);
  }

  sendTestMail(mailBody: any) {
    return this.http.post<MailConfigModel>(BACKEND_URL + `/test/${this.partnerId}`, mailBody);
  }
}
