import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerBondingApiService } from 'src/app/services/partner-bonding-api.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-shared-bonding-api-modal',
  templateUrl: './shared-bonding-api-modal.component.html',
  styleUrls: ['./shared-bonding-api-modal.component.scss']
})
export class SharedBondingApiModalComponent implements OnInit {
  bondingApiForm: FormGroup;
  errors: any;
  isEdit: any;
  apiInfo: any;
  submitted: any = false;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef,
    private bondService: PartnerBondingApiService, private partnerBondingService: PartnerBondingService,
    public activeModal: NgbActiveModal, private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.isEdit) this.bondingApiForm = this.createForm(this.apiInfo);
    else this.bondingApiForm = this.createForm();
  }

  get f() {
    return this.bondingApiForm.controls;
  }

  createForm(data: any = {}) {
    return this.fb.group({
      email: [data?.email || '', [Validators.required]],
      pass: [data?.pass || '', [Validators.required]],
      url: [data?.url || '', [Validators.required]],
      provisioning_url: [data?.provisioning_url || ''],
    })
  }

  onSave() {
    this.submitted = true;
    if (this.bondingApiForm.invalid) return;
    if (this.isEdit) {
      this.sharedService.showLoader();
      this.bondService.updateBondingApi(this.bondingApiForm.value).subscribe((editData: any) => {
        if (editData.code == 200) {
          this.sharedService.loggerSuccess(editData.message);
          this.partnerBondingService.setUpSocket();
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        this.errors = err;
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      });
    } else {
      this.sharedService.showLoader();
      this.bondService.createBondingApi(this.bondingApiForm.value).subscribe((addData: any) => {
        if (addData.code == 200) {
          this.partnerBondingService.setUpSocket();
          this.sharedService.loggerSuccess(addData.message);
        }
        this.sharedService.hideLoader();
        this.cd.detectChanges();
        this.activeModal.close({ event: 'save' });
      }, (err) => {
        this.errors = err;
        this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      });
    }
  }

  onClose() {
    this.activeModal.close({ event: 'close' });
  }
}
