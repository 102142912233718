import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + "/partnerPanel/user";

@Injectable({ providedIn: 'root' })
export class PartnerUserManagementService {

  mainId = this.storageService.getCipherObj('mainId');
  loginId = this.storageService.getCipherObj('ID');

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getAllUsers(
    status: string,
    search: string,
    page: string,
    limit: any,
    name: any,
    sort: any
  ) {
    let loginId = this.storageService.getCipherObj('ID');
    let params = new HttpParams()
      .set('status', status ? status : 'true')
      .set('fname', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    return this.http.get<any>(BACKEND_URL + "/list/" + this.mainId + "/" + loginId, { params });

  }

  selectGroups() {
    let url = environment.apiUrl + `/partnerPanel/permission/selectGroup/${this.mainId}`;
    return this.http.get<any>(url);
  }

  getUserDetailsByID(id: any) {
    return this.http.get<any>(`${BACKEND_URL}/view/${id}/${this.mainId}`);
  }

  updateUser(id: any, body: any) {
    return this.http.put<any>(`${BACKEND_URL}/update/${id}/${this.mainId}`, body);
  }

  addNewUser(body: any) {
    this.mainId = this.storageService.getCipherObj('mainId');
    if (!this.mainId) return;
    return this.http.post<any>(BACKEND_URL + "/add/" + this.mainId, body);
  }

  suspendUser(id: any) {
    return this.http.put<any>(`${BACKEND_URL}/suspend/${id}/${this.mainId}`, {});
  }

  deleteUser(id: any) {
    return this.http.delete<any>(`${BACKEND_URL}/delete/${id}/${this.mainId}`, {});
  }

}
