<div class="modal-header px-5 py-3">
    <h5 class="modal-title">{{ isEdit ? 'Edit Bonding API' : 'Add Bonding API'}} </h5>
    <button type="button" class="btn-close" (click)="onClose()" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body p-5">
    <form [formGroup]="bondingApiForm">
        <div class="card mb-5 mb-xl-0">
            <div class="card-body p-0">
                <div class="row" *ngIf="errors">
                    <div class="col-md-12">
                        <div class="alert alert-danger">{{errors}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Username </label>
                            <input formControlName="email" type="text" name="email"
                                class="form-control form-control-lg form-control-solid" autocomplete="off" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['email']?.join(' ')}} </span>
                                <span *ngIf="submitted==true && f.email.invalid && f.email.errors?.required"
                                    class="text-danger"> This field is required.
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 mb-1">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label">Password</label>
                            <input formControlName="pass" type="password" autocomplete="off"
                                class="form-control form-control-lg form-control-solid" name="pass" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['pass']?.join(' ')}} </span>
                                <span *ngIf="submitted==true && f.pass.invalid && f.pass.errors?.required"
                                    class="text-danger"> This field is required.
                                </span>
                            </small>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 mb-3">
                        <div class="form-group inputtext-block mb-5">
                            <label class="form-label"> URL </label>
                            <input formControlName="url" type="text" autocomplete="off"
                                class="form-control form-control-lg form-control-solid" name="url" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['url']?.join(' ')}} </span>
                                <span *ngIf="submitted==true && f.url.invalid && f.url.errors?.required"
                                    class="text-danger"> This field is required.
                                </span>
                            </small>
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12 col-lg-12">
                        <div class="form-group inputtext-block mb-3">
                            <label class="form-label"> Zero Touch Node Provisioning URL </label>
                            <input formControlName="provisioning_url" type="text" autocomplete="off"
                                class="form-control form-control-lg form-control-solid" name="provisioning_url" />
                            <small class="form-invalid-message">
                                <span class="text-danger" *ngIf="errors"> {{errors['provisioning_url']?.join(' ')}}
                                </span>
                                <!-- <span
                                    *ngIf="submitted==true && f.provisioning_url.invalid && f.provisioning_url.errors?.required"
                                    class="text-danger"> This field is required.
                                </span> -->
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer px-5 py-3">
    <button type="button" class="btn btn-primary btn-style" data-bs-dismiss="modal" (click)="onSave()">
        <div>
            <span *ngIf="isEdit" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr012.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
            <span *ngIf="!isEdit" [inlineSVG]="'./assets/media/icons/normal/plus.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> {{isEdit ? 'Save' : 'Add'}}
    </button>
    <button type="button" class="btn btn-secondary btn-style" data-bs-dismiss="modal" (click)="onClose()">
        <div>
            <span [inlineSVG]="'./assets/media/icons/normal/xmark-solid.svg'"
                class="svg-icon svg-icon-block mb-2"></span>
        </div> Cancel
    </button>
</div>