import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormErrorService } from 'src/app/shared/services/fromError.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-node-provisioning-modal',
  templateUrl: './node-provisioning-modal.component.html',
  styleUrls: ['./node-provisioning-modal.component.scss']
})
export class NodeProvisioningModalComponent implements OnInit {
  nodeForm: FormGroup;
  MAC_ADDRESS_REGEX = /^([0-9a-f]{2}[:-]){5}([0-9a-f]{2})$/;
  pvsList: any;

  constructor(public activeModal: NgbActiveModal, private formErrorHandler: FormErrorService,
    private sharedService: SharedService, private _fb: FormBuilder,) { }

  ngOnInit(): void {
    this.nodeForm = this._fb.group({ mac: ['', [Validators.required, Validators.pattern(this.MAC_ADDRESS_REGEX)]] })
  }

  onSave() {
    if (!this.nodeForm.valid) {
      this.nodeForm.markAsTouched();
      return;
    }
    if (this.pvsList && this.pvsList.length > 0) {
      let find = this.pvsList.find((pvs: any) => pvs.mac === this.nodeForm.value.mac?.toLowerCase());
      if (find) {
        this.sharedService.loggerError('Process is already in running for this device.Kindly wait for the process to complete.');
        return;
      }
    }
    this.activeModal.close({ mac: this.nodeForm.value.mac?.toLowerCase() })
  }

  controlHasError(validation: any, controlName: string): boolean {
    return this.formErrorHandler.controlHasError(validation, controlName, this.nodeForm);
  }

  onMacAddressInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const cleanedValue = input.value.replace(/[^0-9a-f:]/g, '');
    this.nodeForm.controls.mac.setValue(cleanedValue, { emitEvent: false });
  }
}