import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { environment } from 'src/environments/environment';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root', })
export class AuthHTTPService {

  constructor(private http: HttpClient, private storageService: StorageService) { }

  login(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/login`, data);
  }

  resendOTP(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/reSendOtp`, data);
  }

  partnerLogin(body: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/user/login`, body);
  }

  refreshToken(): Observable<any> {
    let mainId = this.storageService.getCipherObj('mainId');
    return this.http.get(`${API_USERS_URL}/auth/refreshToken/${mainId}`);
  }

  getParent(email: string, mainId: string) {
    return this.http.post(`${API_USERS_URL}/auth/getParent`, { email, mainId })
  }

  forgotPassword(payload: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/forgotPassword`, payload);
  }

  forgotPartnerPassword(payload: any): Observable<any> {
    let mainId = this.storageService.getCipherObj('mainId');
    return this.http.post(`${API_USERS_URL}/auth/forgotPassword/${mainId}`, payload);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/admin/reset`, data);
  }

  getBrandSettingBySubdomain(subDomain: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/getBrandByDomain`, { subDomain });
  }

  createPassword(data: any): Observable<any> {
    return this.http.post(`${API_USERS_URL}/auth/user/reset`, data);
  }

  getUserByToken(token: string): Observable<any> {
    let role = this.storageService.getCipherObj('role')
    const httpHeaders = new HttpHeaders({ Authorization: `Bearer ${token}` });
    if (Number(role) == AppConst.admin || Number(role) == AppConst.basicUser) {
      return this.http.get<any>(`${API_USERS_URL}/adminPanel/staff/profile`, {
        headers: httpHeaders,
      });
    } else if (Number(role) == AppConst.partner) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: this.storageService.getCipherObj('mainId'),
      });
    } else if (Number(role) == AppConst.user) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: this.storageService.getCipherObj('mainId'),
      });
    } else if (Number(role) == AppConst.organization) {
      return this.http.post<any>(`${API_USERS_URL}/partnerPanel/getProfile`, {
        headers: httpHeaders,
        id: this.storageService.getCipherObj('mainId'),
      });
    } else {
      return of(null);
    }
  }

  logoutFromPartner(): Observable<any> {
    let adminID = this.storageService.getCipherObj('rootAdminID');
    let mainId = this.storageService.getCipherObj('mainId');
    return this.http.post(`${API_USERS_URL}/partnerPanel/PartnerLPLogout/${mainId}`, { adminID });
  }

  logoutFromUser(data: any): Observable<any> {
    let mainId = this.storageService.getCipherObj('mainId')
    return this.http.post(`${API_USERS_URL}/partnerPanel/user/UserLPLogout/${mainId}`, data);
  }

  setPartnerLP(data: any): Observable<any> {
    let mainId = this.storageService.getCipherObj('mainId');
    return this.http.put(`${API_USERS_URL}/partnerPanel/setPartnerLP/${mainId}`, data);
  }

}
