import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { DrawerComponent } from 'src/app/_metronic/kt/components';
import { AuthService } from 'src/app/modules/shared/auth';
import { AlertService } from 'src/app/services/alert.service';
import { RhsService } from 'src/app/services/rhs.service';
import { PartnerBondNetworkDetailsService } from 'src/app/services/partner-bond-network-details.service';
import { PartnerBondingService } from 'src/app/services/partner-bonding.service';
import { PartnerMailConfigService } from 'src/app/services/partner-mail-config.service';
import { PartnerSpaceService } from 'src/app/services/partner-space.service';
import { NgbModalBondClone } from 'src/app/shared/components/shared-bonds/sdwan-bonds-listing/sdwan-bonds-listing.component';
import { SharedBondsViewSpeedTestComponent } from 'src/app/shared/components/shared-bonds/shared-bonds-view/shared-bonds-view-speed-test/shared-bonds-view-speed-test.component';
import { AppConst } from 'src/app/shared/constants/app.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { AlertConfigurationFormComponent } from './alert-configuration/alert-configuration-form.component';
import { NodeProvisioningModalComponent } from './node-provisioning-modal/node-provisioning-modal.component';
import { TuneBondSettingComponent } from './tune-bond-setting/tune-bond-setting.component';
import { StorageService } from 'src/app/services/storage.service';

const RHS_HOST = environment.rhsHost;

@Component({
  selector: 'app-partner-bond-view',
  templateUrl: './partner-bond-view.component.html',
  styleUrls: ['./partner-bond-view.component.scss']
})
export class PartnerBondViewComponent implements OnInit, OnDestroy {
  @ViewChild('speedTest') speedTest: SharedBondsViewSpeedTestComponent;
  authService = AuthService;
  bondPermissionsObj: any = { view: true, update: true, delete: true, clone: true };
  [x: string]: any;
  bondId: number;
  bonderId: number;
  bondStatus: any;
  bonderData: any;
  allSpeedTest: any[] = [];
  allNodeDetails: any[] = [];
  nodeProvisionData: any;
  speedLength: any;
  selectedTab: any = 1;
  allLegs: any[];
  lengthLegs: any;
  allbonds: any[];
  mergeArray: any[] = [];
  tuneId: any;
  legId: any;
  latest_tuning: any;
  isConfig: boolean = false;
  downTime: any;
  BondFailbackMessage: any = false;
  bondfailbackStatus: boolean;
  alertPermission: boolean = false;
  isAdminMail: boolean = false;
  isSharedBond: boolean = false;
  isSdwanSharedBond: boolean = false;
  isPartner: boolean = false;
  isNodeProvisionData: boolean = false;
  nodeProvisionTimer: any = { hour: 0, minutes: 0, seconds: 0 };
  targetDateTime: any;
  intervalId: any;

  // bond activity 
  bondActivityArr: any[] = [];
  bondActivityLength: any;
  deviceActivityArr: any[] = [];
  deviceActivityLength: any;
  alertActivityArr: any[] = [];
  alertActivityLength: any;

  //add on
  illuminatePlanAccessList: any[] = [];
  selectedIlluminatePlan: any;
  isTrafficAnalyticViewData: boolean = false;
  isTrafficAnalyticManageAgent: boolean = false;
  lastInstalledLog: any;
  lastProvisionedLog: any;
  illuminateMac: any;
  isIlluminate: boolean = false;

  // rhs
  @ViewChild('nodeTunnelPopupButton') nodeTunnelPopupButton: any;
  nodeTunnelForm: FormGroup;
  bondTunnelData: any;
  intervalTunnelStatus: any;
  isTunnelActive: boolean = true;
  tunnelStatusCheckCount: number = 0;
  expiryOptionList: any[] = [
    { name: "5 Minutes", value: 5 },
    { name: "30 Minutes", value: 30 },
    { name: "60 Minutes", value: 60 },
    { name: "8 Hours", value: 480 },
  ];
  nepeanNexusPermission: boolean = false;
  nodeTunnelEditLoader: boolean = false;
  nodeTunnelDeleteLoader: boolean = false;
  isTunnelError: boolean = false;
  addOnPackagesPermission: any = { view: false, update: false, delete: false, clone: false };
  toolDiagnosticsPermission: any = { view: false, update: false, delete: false, clone: false };
  SecureConnectTunnelPermission: any = { view: false, update: false, delete: false, clone: false };

  // firewall
  toolList: any = [];
  pvsList: any = [];
  firewallData: any;
  UtilitiesData: any;
  firewallTunnelData: any;
  firewallTunnelEditLoader: boolean = false;
  firewallTunnelDeleteLoader: boolean = false;

  constructor(private router: Router, private fb: FormBuilder,
    private activatedRouter: ActivatedRoute,
    private PartnerBondingService: PartnerBondingService,
    public sharedService: SharedService, private rhsService: RhsService,
    public storageService: StorageService,
    private cd: ChangeDetectorRef, private spaceService: PartnerSpaceService,
    private partnerBondNetworkService: PartnerBondNetworkDetailsService,
    private partnerMailConfigService: PartnerMailConfigService,
    private modalService: NgbModal, private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.isConfig = this.activatedRouter.snapshot.queryParamMap.get('isconfig') ? true : false;
    if (this.isConfig) this.selectedTab = 2;
    if (Number(this.storageService.getCipherObj('role')) === AppConst.partner) this.isPartner = true;
    this.getCachingBondData();
    this.getPermission();
    this.getAlertPermission();
    this.getRoutes();
    this.getMailData();
    this.createNodeTunnelForm();
  }

  ngOnDestroy() {
    this.storageService.removeCipherText('bondId');
    this.storageService.removeCipherText('bonderId');
    this.storageService.removeCipherText('isSharedBond');
    this.storageService.removeCipherText('isSdwanSharedBond');
    this.storageService.removeCipherText('bondPermission');
    this.storageService.removeCipherText('bondName');
    this.PartnerBondingService.stopGetCachedData(this.bondId);
    this.rhsService.tunnelListDataByBond.next([]);
    this.PartnerBondingService.latestTuningData.next(null);
  }

  getCachingBondData() {
    this.PartnerBondingService.latestTuningData$.subscribe(res => {
      if (!res) this.latest_tuning = null;
      else this.latest_tuning = res;
      this.cd.detectChanges();
    })
    this.PartnerBondingService.bondData$.subscribe(res => {
      if (!res) return;
      this.bondStatus = res;
      this.storageService.setCipherObj('bondName', this.bondStatus?.bonder?.name);
      this.status = this.bondStatus?.bonder?.status;
      this.checkTuning();
      this.checkTunnelExpiry();
      this.cd.detectChanges();
    })
  }

  getPermission() {
    if (Number(this.storageService.getCipherObj('role')) == AppConst.partner) {
      this.bondPermissionsObj = { view: true, update: true, delete: true, clone: true }
      this.addOnPackagesPermission = { view: true, update: true, delete: true }
      this.toolDiagnosticsPermission = { view: true, update: true, delete: true }
      this.SecureConnectTunnelPermission = { view: true, update: true, delete: true }
      this.isTrafficAnalyticManageAgent = true;
      this.isTrafficAnalyticViewData = true;
    } else {
      this.sharedService.getPermission();
      if (this.sharedService.noPermission) {
        this.bondPermissionsObj = { view: true, update: true, delete: true, clone: false }
        this.isTrafficAnalyticManageAgent = false;
        this.isTrafficAnalyticViewData = false;
      } else {
        this.bondPermissionsObj = { ...this.sharedService.bond, clone: this.sharedService.privateWanCloneBond?.update || false };
        this.addOnPackagesPermission = this.sharedService.addOnPackages;
        this.toolDiagnosticsPermission = this.sharedService.ToolDiagnostics;
        this.SecureConnectTunnelPermission = this.sharedService.SecureConnectTunnel;
        this.isTrafficAnalyticManageAgent = this.sharedService.trafficAnalyticManageAgent?.update || false;
        this.isTrafficAnalyticViewData = this.sharedService.trafficAnalyticViewData?.view || false;;
      }
    }
  }

  getAlertPermission() {
    let moduleList = this.storageService.getCipherObj('Module_Access');
    if (moduleList && moduleList.length > 0) {
      moduleList.forEach((item: any) => {
        if (item && item.name == AppConst.moduleList.alertModule) this.alertPermission = true;
        if (item && item.name == AppConst.moduleList.illuminateModule) this.isIlluminate = true;
        if (item && item.name == AppConst.moduleList.nepeanNexusModule) this.nepeanNexusPermission = true;
      });
    }
  }

  getMailData() {
    this.partnerMailConfigService.getMailViewData().subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res.data) {
        if (res.data.administrationEmail) this.isAdminMail = true;
      } else console.log(res);
      this.cd.detectChanges();
    }, (err: any) => {
      console.log(err);
    });
  }

  getInstallFirewall() {
    this.sharedService.Loader.isFirewallListLoader = true;
    this.PartnerBondingService.getInstalledFirewall(this.bondId)?.subscribe((res) => {
      if (res.code != 400) {
        if (res.firewallData && res.firewallData.bondId == this.bondId) {
          this.firewallData = res.firewallData;
          this.firewallTunnelData = res.tunnelData;
        } else {
          this.firewallData = null;
          this.firewallTunnelData = null;
        }
        if (res.toolList && res.toolList.length > 0) {
          this.toolList = res.toolList;
          let find = this.toolList.find((tool: any) => tool.type == 'Tool' && tool.tool_type == AppConst.toolsType.NepeanControlUtilities);
          if (find) this.UtilitiesData = find;
          else this.UtilitiesData = null;
        } else {
          this.toolList = [];
          this.UtilitiesData = null;
        }
        if (res.pvsList && res.pvsList.length > 0) {
          this.pvsList = res.pvsList?.filter((item: any) => item?.bondkey == this.bondStatus?.bonder?.key.match(/.{1,5}/g)?.join('-'));
        } else this.pvsList = [];
      } else {
        this.toolList = [];
        this.pvsList = [];
        this.firewallData = null;
      }
      this.sharedService.Loader.isFirewallListLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      this.firewallData = null;
      this.UtilitiesData = null;
      this.toolList = [];
      this.pvsList = [];
      console.log(err);
      this.sharedService.Loader.isFirewallListLoader = false;
      this.cd.detectChanges();
    });
  }

  getNodeProvisionData() {
    this.PartnerBondingService.viewNodeProvisioning(this.bondId)?.subscribe((res) => {
      if (res.code != 400 && res.data) {
        if (res.data.bondId == this.bondId) {
          this.isNodeProvisionData = true;
          this.nodeProvisionData = res.data;
          if (this.nodeProvisionData?.createdBy) this.initializeTimer(this.nodeProvisionData.createdAt);
        } else this.isNodeProvisionData = false;
      } else this.isNodeProvisionData = false;
      this.cd.detectChanges();
    }, (err) => {
      this.isNodeProvisionData = false;
      console.log(err);
      this.cd.detectChanges();
    });
  }

  initializeTimer(startDateTime: string): void {
    const startTime = moment(startDateTime);
    const currentTime = moment();
    const diff = moment.duration(currentTime.diff(startTime));
    const remainingTime = moment.duration(24, 'hours').subtract(diff);
    if (remainingTime.asMilliseconds() <= 0) {
      this.nodeProvisionTimer = { hour: 0, minutes: 0, seconds: 0 };
      this.cancelProvisioning();
      return;
    }
    this.targetDateTime = currentTime.add(remainingTime);
    this.updateTimer();
    this.intervalId = setInterval(() => this.updateTimer(), 1000);
  }

  updateTimer(): void {
    const currentTime = moment();
    const duration = moment.duration(this.targetDateTime.diff(currentTime));
    if (duration.asMilliseconds() <= 0) {
      clearInterval(this.intervalId);
      this.nodeProvisionTimer = { hour: 0, minutes: 0, seconds: 0 };
      this.cancelProvisioning();
    } else {
      this.nodeProvisionTimer = {
        hour: Math.floor(duration.asHours()) < 10 ? `0${Math.floor(duration.asHours())}` : Math.floor(duration.asHours()),
        minutes: Math.floor(duration.minutes()) < 10 ? `0${Math.floor(duration.minutes())}` : Math.floor(duration.minutes()),
        seconds: Math.floor(duration.seconds()) < 10 ? `0${Math.floor(duration.seconds())}` : Math.floor(duration.seconds()),
      };
    }
    this.cd.detectChanges();
  }

  getRoutes() {
    this.bondId = this.storageService.getCipherText('bondId') ?? 0;
    this.bonderId = this.storageService.getCipherText('bonderId') ?? 0;
    this.isSdwanSharedBond = this.storageService.getCipherBoolean('isSdwanSharedBond') ?? false;
    this.isSharedBond = this.storageService.getCipherBoolean('isSharedBond') ?? false;
    this.sharedService.showLoader();
    if (this.bondId && this.bonderId) {
      this.PartnerBondingService.bondId.next({ bondId: this.bondId });
      this.PartnerBondingService.bonderId.next({ bonderId: this.bonderId });
      this.PartnerBondingService.setUpSocket();
      this.PartnerBondingService.startGetCachedData(this.bondId);
      this.activeLegList();
      this.getAllAlertList();
      this.getBondStatus(false);
      this.getNodes();
      this.getFailMessage();
      this.getRecentActivity();
      this.getEthernetInterfaceById();
      if (this.bondPermissionsObj.update) this.getNodeProvisionData();
      this.cd.detectChanges();
    } else {
      this.sharedService.hideLoader();
      this.onBack();
    }
  }

  activeLegList() {
    if (!this.bondId) return;
    this.alertService.activeLegList(this.bondId)?.subscribe((res: any) => {
      if (res && res.data) this.alertService.activeAlertLegList.next(res.data);
    });
  }

  getAllAlertList() {
    this.alertService.getAllAlertListing()?.subscribe((res: any) => {
      if (res && res?.data) {
        res?.data?.forEach((alert: any) => {
          if (alert && alert.bondList && alert.bondList.length > 0) alert.bondList = alert.bondList.map((bond: any) => bond.id);
        })
        this.alertService.alertGroupData.next(res.data);
      }
    });
  }

  getBondStatus(isShowLoader: boolean = true) {
    let url = `bonders/${this.bonderId}/`;
    let url1 = `bonds/${this.bondId}/`;
    if (isShowLoader) this.sharedService.showLoader();
    this.sharedService.Loader.isBondStatusLoader = true;
    combineLatest([
      this.PartnerBondingService.viewBonderStatus('GET', url, this.bondId),
      this.PartnerBondingService.viewStatus('GET', url1)
    ]).subscribe(([res1, res2]: any[]) => {
      if (res1) this.bonderData = res1?.data;
      if (res2) {
        this.bondStatus = res2?.data;
        this.status = this.bondStatus?.bonder?.status;
        this.checkTuning();
        if (this.nepeanNexusPermission) {
          this.getRHSTunnelList();
          this.getInstallFirewall();
        }
        this.PartnerBondingService.bondData.next(this.bondStatus);
        this.cd.detectChanges();
      }
      this.sharedService.Loader.isBondStatusLoader = false;
      if (isShowLoader) this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.Loader.isBondStatusLoader = false;
      this.sharedService.loggerError(err);
      if (isShowLoader) this.sharedService.hideLoader();
    });
  }

  checkTuning() {
    if (this.bondStatus?.tuning && this.bondStatus?.tuning?.tuner) {
      if (this.latest_tuning?.status != 'tuned') this.latest_tuning = this.bondStatus?.tuning
      if (this.bondStatus?.tuning?.tuner.includes(`bonds/${this.bondId}/tuners/`)) {
        // bond tuning
        this.latest_tuning.bond = this.latest_tuning.tuner;
        this.tuneId = this.latest_tuning.tuner?.split('/')[8];
        this.getLatestTuning(this.tuneId, '');
      } else {
        // leg tuning
        this.latest_tuning.leg = this.latest_tuning.tuner;
        this.legId = this.latest_tuning.tuner?.split('/')[8];
        this.tuneId = this.latest_tuning.tuner?.split('/')[10];
        this.latest_tuning.legId = this.legId;
        this.latest_tuning.tuneId = this.tuneId;
        if (!this.latest_tuning.status) this.getLatestTuning(this.tuneId, this.legId);
      }
    } else this.latest_tuning = null;
    this.PartnerBondingService.latestTuningData.next(this.latest_tuning);
  }

  getNodes() {
    let url = `nodes/${this.bonderId}/`;
    this.partnerBondNetworkService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res.data) this.allNodeDetails = res.data;
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    });
  }

  getFailMessage() {
    let url = `alerts/bond/${this.bondId}/`, method = "GET";
    this.PartnerBondingService.getFailbackMessage(method, url).subscribe((res: any) => {
      if (res && res.data.alerts) {
        this.PartnerBondingService.failbackMessage.next(res.data.alerts);
        this.BondFailbackMessageArr = res.data.alerts;
      }
      this.cd.detectChanges();
    })
  }

  removeBondFailbackMessage(index: any) {
    this.BondFailbackMessageArr.splice(index, 1);
  }

  getRecentActivity() {
    this.bondActivityArr = [];
    this.deviceActivityArr = [];
    this.alertActivityArr = [];
    this.bondActivityLength = 0;
    this.deviceActivityLength = 0;
    this.alertActivityLength = 0;
    this.sharedService.Loader.isBondUpdateLogLoader = true;
    this.PartnerBondingService.getBondActivity(this.bondId).subscribe((response: any) => {
      if (response && response.data) {
        if (response.data.bondActivity) {
          this.bondActivityArr = response.data.bondActivity;
          this.bondActivityLength = this.bondActivityArr?.length;
        }
        if (response.data.alertActivity) {
          this.alertActivityArr = response.data.alertActivity;
          this.alertActivityLength = this.alertActivityArr?.length;
        }
        if (response.data.provisionLog) {
          this.deviceActivityArr = response.data.provisionLog;
          this.PartnerBondingService.deviceLogList.next(this.deviceActivityArr);
          if (this.deviceActivityArr.length > 0) {
            for (let logs of this.deviceActivityArr) {
              if (logs?.type === 'illuminate_installation') {
                this.lastInstalledLog = logs;
                break;
              }
            }
            for (let logs of this.deviceActivityArr) {
              if (logs?.type === 'illuminate_provision') {
                this.lastProvisionedLog = logs;
                break;
              }
            }
          }
          this.deviceActivityLength = this.deviceActivityArr?.length;
        }
        if (response.data.tuneActivityList) {
          let legTuneActivity = response.data.tuneActivityList?.filter((item: any) => item.type == 'Leg Tuning');
          if (legTuneActivity.length > 0) {
            this.PartnerBondingService.legTuningList.next(legTuneActivity);
          }
          let bondTuneActivity = response.data.tuneActivityList?.filter((item: any) => item.type == 'Bond Tuning');
          if (bondTuneActivity.length > 0) {
            this.PartnerBondingService.bondTuningList.next(bondTuneActivity);
          }
        }
      }
      this.sharedService.Loader.isBondUpdateLogLoader = false;
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.loggerError(err);
      this.sharedService.Loader.isBondUpdateLogLoader = false;
      this.cd.detectChanges();
    })
  }

  getEthernetInterfaceById() {
    let url = `bonders/${this.bonderId}/ethernet_interfaces/`, method = "GET";
    this.PartnerBondingService.viewBond(method, url).subscribe((ethernetData: any) => {
      if (ethernetData && ethernetData.data && ethernetData.data.length > 0) {
        let macList: any[] = [];
        ethernetData.data.forEach((item: any) => {
          if (item && (item?.mac || item?.status?.mac)) {
            macList.push(item?.status?.mac ? item?.status?.mac : item?.mac);
          }
        });
        if (macList && macList.length > 0) {
          let finalMac = macList.reduce((lowest, current) => {
            const normalizedCurrent = this.normalizeMac(current);
            const normalizedLowest = this.normalizeMac(lowest);
            return normalizedCurrent < normalizedLowest ? current : lowest;
          });
          if (finalMac) {
            this.illuminateMac = finalMac.toLowerCase();
            this.PartnerBondingService.bond_eth0_mac.next(this.illuminateMac);
          }
        }
      }
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.Loader.isEthernetInterfaceLoader = false;
      this.sharedService.loggerError(err);
      this.cd.detectChanges();
    })
  }

  normalizeMac(mac: string): string {
    return mac.replace(/:/g, '').toUpperCase();
  }

  getRHSTunnelList() {
    this.rhsService.tunnelListByBond(this.bondId).subscribe((res: any) => {
      if ((res.code == 200 || res.code == 201) && res.data) {
        if (res.data.length > 0) {
          this.rhsService.tunnelListDataByBond.next(res.data);
          this.bondTunnelData = res.data?.find((tunnel: any) => tunnel.bondId == this.bondId && tunnel.type == AppConst.tunnelType.nodeTunnel);
          if (this.bondTunnelData && this.bondTunnelData.expireSession) {
            const startTime = moment(this.bondTunnelData.expireSession);
            const currentTime = moment();
            const diff = moment.duration(startTime.diff(currentTime));
            if (diff.asMinutes() <= 0) this.deleteTunnel();
            else {
              // if (!this.isTunnelActive) {
              //   if (!this.intervalTunnelStatus) this.intervalTunnelStatus = setInterval(() => this.checkActiveTunnel(), 5000);
              // }
            }
          }
        } else {
          this.bondTunnelData = null;
          this.rhsService.tunnelListDataByBond.next([]);
        }
      } else console.log(res);
      this.cd.detectChanges();
    }, (err: any) => {
      console.log(err);
    });
  }

  checkTunnelExpiry() {
    if (this.bondTunnelData && this.bondTunnelData.expireSession) {
      const startTime = moment(this.bondTunnelData.expireSession);
      const currentTime = moment();
      const diff = moment.duration(startTime.diff(currentTime));
      if (diff.asMinutes() <= 0) this.deleteTunnel();
    }
  }

  onTabChange(tab: any) {
    if (this.selectedTab != tab) {
      this.selectedTab = tab;
      if (this.selectedTab == 4) {
        this.getRecentActivity();
        if (this.isIlluminate && this.isTrafficAnalyticViewData) this.getIlluminatePlanList();
      }
      if (this.selectedTab == 7) {
        if (this.isIlluminate && this.isTrafficAnalyticViewData) this.getIlluminatePlanList();
      }
      if (this.selectedTab == 8) {
        if (this.nepeanNexusPermission) this.getInstallFirewall();
      }
      this.cd.detectChanges();
    }
  }

  onBack() {
    this.router.navigate(['/partner/bonds'])
  }

  navigateToSpace(spaceDetails: any) {
    if (!spaceDetails) return;
    this.spaceService.setSpaceParentId(spaceDetails.key);
    this.spaceService.setSpaceData(spaceDetails);
    this.storageService.setCipherText('space', spaceDetails.key);
    this.cd.detectChanges();
    this.router.navigate([`/partner/sd-wan/edit`], { queryParams: { isconfig: true } });
  }

  navigateToSpeed() {
    this.router.navigate([`/partner/bonds/${this.bondId}/legs/${this.legId}/speedcheck/${this.tuneId}/`])
  }

  getIlluminatePlanList() {
    if (this.illuminatePlanAccessList?.length > 0) return;
    this.sharedService.Loader.isIlluminatePlanListLoader = true;
    this.PartnerBondingService.getIlluminatePlanListUser()?.subscribe((res: any) => {
      if (res && res.data) {
        this.illuminatePlanAccessList = res.data.length > 0 ? res.data : [];
        if (this.illuminatePlanAccessList.length > 0) this.selectedIlluminatePlan = this.illuminatePlanAccessList[0].key;
      } else this.sharedService.loggerError('invalid plan data');
      this.sharedService.Loader.isIlluminatePlanListLoader = false;
      this.cd.detectChanges();
    }, (err: any) => {
      this.sharedService.loggerError(err);
      this.sharedService.Loader.isIlluminatePlanListLoader = false;
      this.cd.detectChanges();
    });
  }

  getSpeedTest() {
    this.sharedService.showLoader();
    let url = `bonds/${this.bondId}/speedtests/`;
    this.partnerBondNetworkService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res.data) {
        this.speedLength = res.data.length;
        this.allSpeedTest = res.data;
      } else this.speedLength = 0;
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.speedLength = 0;
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  getAllInterfaces() {//interface legs called from here
    let url = `bonds/${this.bondId}/interface_legs/`
    this.partnerBondNetworkService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res.data) {
        this.allLegs = res.data;
        this.lengthLegs = res.data.length;
        this.allLegs.map((leg) => {
          if (leg.bonder_status?.state == "up") {
            let n = { name: "", url: "" };
            n.name = "Interface leg " + leg.id + "," + leg.ifname;
            n.url = leg.url;
            this.mergeArray.push(n);
            this.cd.detectChanges();
          }
        })
        //this.allLegs = res.data;
        for (let i = 0; i < this.lengthLegs; i++) {
          res.data[i].type = 'Interface'
          this.partnerBondNetworkService.getResponse(res.data[i].dhcp_addressings_url, 'GET').subscribe((res1: any) => {
            if (res1.data) res.data[i].dhcpData = res1.data;
          });

          this.partnerBondNetworkService.getResponse(res.data[i].auto_ipv6_addressings_url, 'GET').subscribe((res1: any) => {
            if (res1.data) res.data[i].autov6Data = res1.data;
          });

          this.partnerBondNetworkService.getResponse(res.data[i].static_addressings_url, 'GET').subscribe((res1: any) => {
            if (res1.data) res.data[i].staticData = res1.data;
          });

          this.partnerBondNetworkService.getResponse(res.data[i].pppoe_addressings_url, 'GET').subscribe((res1: any) => {
            if (res1.data) res.data[i].pppoeData = res1.data;
          });
          this.allLegs[i] = res.data[i];
        }
        this.lengthLegs = this.allLegs.length;
        // /        this.allInterfaces.forEach(x => x.isCollapsed = true)
      } else this.lengthLegs = 0;
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  getBonds() {
    let url = `bonds/${this.bondId}/`
    this.sharedService.showLoader();
    this.partnerBondNetworkService.getResponse(url, 'GET').subscribe((res: any) => {
      if (res.data) {
        this.mergeArray.push({ name: res.data.name, url: res.data.url });
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  onAddTest(data: any) {
    this.sharedService.showLoader();
    this.partnerBondNetworkService.addSpeedTest(data.data).subscribe((res: any) => {
      if (res.code == 201) {
        this.sharedService.loggerSuccess(res.message);
        this.speedTest.TestAdd.reset();
        this.getSpeedTest();
      }
      this.sharedService.hideLoader();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  getRestart() {
    if (!this.bondId) return;
    this.sharedService.showLoader();
    this.PartnerBondingService.getRestart(this.bondId)?.subscribe((res: any) => {
      if (res) this.sharedService.loggerSuccess(`bond ${this.bondId} is being restarted.`);
      else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  getDetectLegMTU() {
    if (!this.bondId) return;
    this.sharedService.showLoader();
    this.PartnerBondingService.getDetectLegMTU(this.bondId)?.subscribe((res: any) => {
      if (res) this.sharedService.loggerSuccess(`Leg MTUs are being detected.`);
      else this.sharedService.loggerError(res.message);
      this.sharedService.hideLoader();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error?.non_field_errors) this.sharedService.loggerError(error?.non_field_errors);
        else this.sharedService.loggerError(err);
        this.sharedService.hideLoader();
        this.cd.detectChanges();
      } catch (e) {
        // JSON parsing failed, assume it's a plain error message
        this.sharedService.hideLoader();
        this.sharedService.loggerError(err);
        this.cd.detectChanges();
      }
    });
  }

  openTuneSetting() {
    let modal = this.modalService.open(TuneBondSettingComponent, { size: 'lg' });
    modal.componentInstance.bondId = this.bondId;
    modal.componentInstance.bondData = this.bondStatus;
    modal.closed.subscribe((result: any) => {
      if (result.event && result.event.data && result.event.data.id) {
        this.tuneId = result.event.data.id;
        this.getLatestTuning(this.tuneId, '');
        this.getRecentActivity();
      };
    });
  }

  cancelTuning(legId: any) {
    if (!this.bondId) return;
    this.sharedService.showLoader();
    this.PartnerBondingService.cancelTuning(this.bondId, this.tuneId, legId)?.subscribe((res: any) => {
      if (res) {
        this.sharedService.loggerSuccess(`Successfully cancelled tuning.`);
        this.latest_tuning = null;
        this.getRecentActivity();
        this.PartnerBondingService.latestTuningData.next(null);
      } else this.sharedService.loggerError(res.description);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
    });
  }

  viewTuning(legId: any = 0) {
    const drawer = DrawerComponent.getInstance(document.getElementById('kt_engage_demos')!);
    drawer?.show();
  }

  getLatestTuning(tuneId: any, legId: any) {
    if (!this.bondId || !tuneId) return;
    this.legId = legId;
    this.tuneId = tuneId;
    this.PartnerBondingService.getLatestTuning(this.bondId, tuneId, legId)?.subscribe((res: any) => {
      if (res && res.data) {
        this.latest_tuning = res.data;
        this.latest_tuning.tuner = this.latest_tuning.url;
        this.latest_tuning.legId = this.legId;
        this.latest_tuning.tuneId = this.tuneId;
        this.PartnerBondingService.latestTuningData.next(this.latest_tuning);
      } else this.sharedService.loggerError(res.description);
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
    });
  }

  onAlert() {
    let modal = this.modalService.open(AlertConfigurationFormComponent, { size: 'md' });
    modal.componentInstance.bondId = +this.bondId;
  }

  // getAlertHistory() {
  //   let modal = this.modalService.open(AlertHistoryComponent, { size: 'xl' });
  //   modal.componentInstance.bondId = this.bondId;
  // }

  cloneBond(data: any) {
    let payload = {
      "aggregator": data.aggregator,
      "secondary_aggregator": data.secondary_aggregator,
      "aggregator_failback": data.aggregator_failback || false,
      "qos_profile": data.qos_profile ?? null,
      "flow_collectors": null,
      "classification_profile": null,
      "compression": data.compression == false ? false : true,
      "tunnel_security": data.tunnel_security || 'hmac',
      "encryption_cipher": data.encryption_cipher || 'AES128',
      "encryption_handshake_interval": data.encryption_handshake_interval || 3600,
      "encryption_replay_protection": data.encryption_replay_protection == false ? false : true,
      "packet_distribution": data.packet_distribution || 'wrr',
      "flowlet_delta": data.flowlet_delta || 0,
      "batched_leg_tx": data.batched_leg_tx || false,
      "clamp_tcp": data.clamp_tcp == false ? false : true,
      "automatic_ping_timing": data.automatic_ping_timing == false ? false : true,
      "regular_leg_ping_time": data.regular_leg_ping_time || 100,
      "regular_leg_fail_time": data.regular_leg_fail_time || 300,
      "failover_leg_ping_time": data.failover_leg_ping_time || 1000,
      "failover_leg_fail_time": data.failover_leg_fail_time || 3000,
      "automatic_reorder_max_hold": data.automatic_reorder_max_hold == false ? false : true,
      "reorder_max_hold": data.reorder_max_hold || 30,
      "packet_loss_detection": data.packet_loss_detection == false ? false : true,
      "flap_detection": data.flap_detection == false ? false : true,
      "leg_mtu_detection": data.leg_mtu_detection == false ? false : true,
      "leg_mtu_detection_time": data.leg_mtu_detection_time || 1,
      "send_jitter_buffer": data.send_jitter_buffer || false,
      "source_address_verification": data.source_address_verification || false,
      "replify_enabled": data?.replify_enabled || false,
      "debug": data.debug || false,
      "proof_of_concept": data.proof_of_concept || false,
      "bridge_enabled": data.bridge_enabled || false,
      "bridge_ports": data.bridge_portss || '80,443,8080',
      "bridge_concurrency": data.bridge_concurrency || 4,
      "bridge_congestion_control_algorithm": data.bridge_congestion_control_algorithm || 'bbr',
      "bridge_file_descriptor_limit": data.bridge_file_descriptor_limit || 16384,
      "note": data.note || '',
      "circuit_id": data.circuit_id || '',
      "product": data.product || '',
      "asset_tag": data?.bonder?.asset_tag || '',
      "space": data.space ?? null,
      "bonder": {
        "metric_collection_interval": data?.bonder?.metric_collection_interval || 10,
        "administrative_profile": data?.administrative_profile || 'default',
        "note": data.note || '',
        "circuit_id": data.circuit_id || '',
        "product": data.product || '',
        "proof_of_concept": data.proof_of_concept || false,
        "metric_reporting_interval": data?.bonder?.metric_reporting_interval || 60,
        "cpu_governor": data?.bonder?.cpu_governor || '',
        "tcp_congestion_control_algorithm": data.tcp_congestion_control_algorithm || "bbr",
        "conntrack_table_size": data.conntrack_table_size || 131072,
        "manage_process_affinity": data.conntrack_table_size == false ? false : true,
        "tunnel_affinity_core": data.conntrack_table_size || 2,
        "dns_servers": data.dns_servers ? data.dns_servers.split(',') : [],
        "automatic_source_ip": data.automatic_source_ip || false,
        "web_server": data.web_server == false ? false : true,
        "name": data?.bonder?.name + '(copy)',
        "asset_tag": data?.bonder?.asset_tag || '',
        "serial_number": data?.bonder?.serial_number || '',
        "debug": data?.bonder?.debug || false,
      }
    }
    let reqObj = {
      "url": `bonds/?space=${data?.space?.key}`,
      "method": "GET",
      "data": {}
    }
    this.sharedService.showLoader();
    this.spaceService.getSpace(reqObj).subscribe((res: any) => {
      if (res && res.data) {
        let allBonds = res.data.length > 0 ? res.data : [];
        const modalRef = this.modalService.open(NgbModalBondClone)
        modalRef.componentInstance.bondList = allBonds;
        modalRef.componentInstance.cloneBondName = data?.bonder?.name;
        modalRef.closed.subscribe((result: any) => {
          if (result && result.name) {
            payload.bonder.name = result.name;
            this.sharedService.showLoader()
            this.PartnerBondingService.cloneBond(payload, data.id).subscribe((res) => {
              if (res.code != 400 && res.data && (res.data.bondCode == 200 || res.data.bondCode == 201)) {
                res = res.data;
                if (!(res.bonderCode == 200 || res.bonderCode == 201)) {
                  this.sharedService.loggerSuccess('Bond Data cloned, but bonder Data encounter error.');
                } else {
                  let isError = false;
                  if (res.interfaceResponses && res.interfaceResponses.length > 0) {
                    let interfaceError = ''
                    if (!(res.interfaceResponses[0] && res.interfaceResponses[0].ifname == 'eth0')) {
                      interfaceError += 'eth0';
                    } else if (!(res.interfaceResponses[1] && res.interfaceResponses[1].ifname == 'eth1')) {
                      if (interfaceError) interfaceError += ', eth1';
                      else interfaceError += 'eth1';
                    } else if (!(res.interfaceResponses[2] && res.interfaceResponses[2].ifname == 'eth2')) {
                      if (interfaceError) interfaceError += ', eth2';
                      else interfaceError += 'eth2';
                    }
                    if (interfaceError) {
                      this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating interface ${interfaceError}.`);
                      isError = true;
                    }
                  }
                  if (res.wanResponses && res.wanResponses.length > 0) {
                    let wanError = false
                    if (!(res.wanResponses[0] && res.wanResponses[0].id)) {
                      wanError = true;
                    } else if (!(res.wanResponses[1] && res.wanResponses[1].id)) {
                      wanError = true;
                    }
                    if (wanError) {
                      this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating WAN.`);
                      isError = true;
                    }
                  }
                  if (res.dhcpAddressResponses && res.dhcpAddressResponses.length > 0) {
                    if (!(res.dhcpAddressResponses[2] && res.dhcpAddressResponses[2].id)) {
                      this.sharedService.loggerSuccess(`Bond Data cloned, but encounter error while creating connected IP.`);
                      isError = true;
                    }
                  }
                  if (!isError) {
                    this.sharedService.loggerSuccess('Bond Cloned successfully');
                    this.storageService.setCipherText('bondId', res.bond.id);
                    this.storageService.setCipherText('bonderId', res.bonder.id);
                    setTimeout(() => window.location.reload(), 200);
                  }
                }
              } else this.sharedService.loggerError('Internal server error');
              this.sharedService.hideLoader();
              this.cd.detectChanges();
            }, (err) => {
              console.log(err);
              this.sharedService.loggerError('Internal server error');
              this.cd.detectChanges();
            });
          }
        });
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  cancelProvisioning() {
    if (!this.nodeProvisionData._id) return;
    this.sharedService.showLoader()
    this.PartnerBondingService.deleteNodeProvisioning(this.nodeProvisionData._id)?.subscribe((res) => {
      if (res.code != 400) {
        if (res.error) this.sharedService.loggerError(res.error);
        else {
          this.getNodeProvisionData();
          if (res?.message) this.sharedService.loggerSuccess(res?.message);
          else this.sharedService.loggerSuccess("MAC Address removed successfully");
        }
      } else {
        if (res.error) this.sharedService.loggerError(res.error);
        else this.sharedService.loggerError('Internal server error');
      }
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      console.log(err);
      this.sharedService.loggerError('Internal server error');
      this.cd.detectChanges();
    });
  }

  nodeProvisioning() {
    const modalRef = this.modalService.open(NodeProvisioningModalComponent, { size: 'md' });
    modalRef.componentInstance.pvsList = this.pvsList;
    modalRef.closed.subscribe((result: any) => {
      if (result && result.mac) {
        if (!this.bondStatus?.bonder?.key || !this.bondStatus?.name || !this.bondId) {
          this.sharedService.loggerError('invalid bond details');
        }
        let payload = {
          mac: result?.mac,
          name: this.bondStatus?.name,
          bondId: "" + this.bondId,
          bondkey: this.bondStatus?.bonder?.key.match(/.{1,5}/g)?.join('-')
        }
        this.sharedService.showLoader()
        this.PartnerBondingService.addNodeProvisioning(payload)?.subscribe((res) => {
          if (res.code != 400) {
            if (res.error) this.sharedService.loggerError(res.error);
            else {
              this.getNodeProvisionData();
              if (res?.message) this.sharedService.loggerSuccess(res?.message);
              else this.sharedService.loggerSuccess("Node provisioned successfully");
            }
          } else {
            if (res.error) this.sharedService.loggerError(res.error);
            else this.sharedService.loggerError('Internal server error');
          }
          this.sharedService.hideLoader();
          this.cd.detectChanges();
        }, (err) => {
          console.log(err);
          this.sharedService.loggerError('Internal server error');
          this.cd.detectChanges();
        });
      }
    });
  }

  loginToIlluminate(): void {
    this.sharedService.showLoader();
    let key = this.keyFormate(this.bonderData?.key);
    this.PartnerBondingService.generateIlluminateToken(key)?.subscribe((res: any) => {
      if (res.code == 200 && res?.data?.token && res?.data?.url) window.open(res?.data?.url + '?token=' + res?.data?.token, '_blank')
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    }, (err) => {
      this.sharedService.loggerError(err);
      this.sharedService.hideLoader();
      this.cd.detectChanges();
    });
  }

  calculateDifferenceOfDate(date: any) {
    if (!date) return '-'
    let startDate = new Date(date);
    let endDate = new Date();
    if (startDate > endDate) {
      let swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    let startYear = startDate.getFullYear();
    let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = -1;
    let minDiff = -1;
    let secDiff = -1;
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    } else hourDiff = endDate.getHours() - startDate.getHours();
    minDiff = endDate.getMinutes() - startDate.getMinutes();
    secDiff = endDate.getSeconds() - startDate.getSeconds();
    if (yearDiff == 1) return `About year ago`;
    else if (yearDiff > 1) return `${parseInt(yearDiff + '')} year ago`;
    else if (monthDiff == 1) return `About month ago`;
    else if (monthDiff > 1 && monthDiff <= 31) return `${parseInt(monthDiff + '')} month ago`;
    else if (dayDiff == 1) return `About day ago`;
    else if (dayDiff >= 1 && dayDiff <= 31) return `${parseInt(dayDiff + '')} day ago`;
    else if (hourDiff >= 1 && hourDiff <= 31) return `${parseInt(hourDiff + '')} hour ago`;
    else if (minDiff >= 1 && minDiff <= 59) return `${parseInt(minDiff + '')} minutes ago`;
    else if (secDiff >= 1 && secDiff <= 59) return `${parseInt(secDiff + '')} seconds ago`;
    else return '-';
  }

  getFormattedMessage(message: any) {
    message = message.replace('Bond', this.bondStatus?.space?.name + '-' + this.bondStatus?.bonder?.name);
    if (message.includes('secondary aggregator')) {
      let messageArr = message.split("secondary aggregator", 2);
      let string = this.calculateDifferenceOfDate(messageArr[1]);
      message = messageArr[0] + 'secondary aggregator ' + string + '.';
      return message;
    } else {
      return message;
    }
  }

  keyFormate(key: string) {
    if (!key) return;
    return key.match(/.{1,5}/g)?.join('-');
  }

  // Tunnel functionality
  createNodeTunnelForm(data: any = {}) {
    this.nodeTunnelForm = this.fb.group({
      ip: [data.ip || "", Validators.required],
      port: [+data.port || 80, Validators.required],
      expiry: [5, Validators.required],
      protocol: ['http', Validators.required],
    });
    this.nodeTunnelForm.controls.protocol.valueChanges.subscribe((data: any) => {
      if (data) {
        if (data == 'http') this.nodeTunnelForm.controls.port.setValue(80);
        else this.nodeTunnelForm.controls.port.setValue(443);
      }
    });
  }

  openTunnelPopup() {
    if (this.bondTunnelData) return;
    this.nodeTunnelDeleteLoader = false;
    this.nodeTunnelEditLoader = false;
    if (this.nodeTunnelForm.controls.protocol.value == 'http') this.nodeTunnelForm.controls.port.setValue(80);
    else this.nodeTunnelForm.controls.port.setValue(443);
    this.nodeTunnelForm.controls.ip.setValue("");
    this.nodeTunnelForm.controls.expiry.setValue(5);
  }

  openConnection() {
    if (this.bondTunnelData && this.bondTunnelData.type == AppConst.tunnelType.nodeTunnel && this.bondTunnelData.body.serverPort && this.bondTunnelData.body.protocol) {
      window.open(`${this.bondTunnelData.body.protocol}://${RHS_HOST}:${this.bondTunnelData.body.serverPort}`, '_blank');
    }
  }

  createUpdateTunnel() {
    if (!this.illuminateMac) {
      this.sharedService.loggerError('Unable to detect Mac Address');
      return;
    }
    if (this.nodeTunnelForm.invalid) {
      this.isTunnelError = true;
      return;
    } this.isTunnelError = false;
    let expiryDate = new Date();
    expiryDate.setMinutes(expiryDate.getMinutes() + this.nodeTunnelForm.controls.expiry.value);
    let reqObject: any = {
      ip: this.nodeTunnelForm.controls.ip.value,
      port: +this.nodeTunnelForm.controls.port.value,
      protocol: this.nodeTunnelForm.controls.protocol.value,
      expire: expiryDate,
      bondName: this.bondStatus?.bonder?.name,
      bondId: this.bondId,
      nodeKey: this.bondStatus?.bonder?.key,
      type: AppConst.tunnelType.nodeTunnel,
      mac: this.illuminateMac,
    };
    this.nodeTunnelEditLoader = true;
    this.rhsService.createUpdateTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.sharedService.loggerSuccess(addRes.message);
        this.isTunnelActive = false;
        setTimeout(() => {
          this.isTunnelActive = true;
          this.cd.detectChanges();
        }, 30000)
        this.getRHSTunnelList();
        this.nodeTunnelPopupButton.close();
      } else this.sharedService.loggerError(addRes.message);
      this.nodeTunnelEditLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.nodeTunnelEditLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.nodeTunnelEditLoader = false;
        this.cd.detectChanges();
      }
    });
  }

  deleteTunnel() {
    let reqObject = { bondId: this.bondId, type: AppConst.tunnelType.nodeTunnel };
    this.nodeTunnelDeleteLoader = true;
    this.rhsService.deleteTunnel(reqObject).subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.bondTunnelData = null;
        this.isTunnelActive = true;
        this.getRHSTunnelList();
        this.sharedService.loggerSuccess(addRes.message);
      } else this.sharedService.loggerError(addRes.message);
      this.nodeTunnelDeleteLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      try {
        this.isTunnelActive = true;
        this.getRHSTunnelList();
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.nodeTunnelDeleteLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.nodeTunnelDeleteLoader = false;
        this.cd.detectChanges();
      }
    });
  }

  checkActiveTunnel() {
    if (!this.isTunnelActive && this.tunnelStatusCheckCount < 12) {
      this.tunnelStatusCheckCount++;
      if (this.bondTunnelData && this.bondTunnelData.type == AppConst.tunnelType.nodeTunnel && this.bondTunnelData.body.serverPort && this.bondTunnelData.body.protocol) {
        let url = `${this.bondTunnelData.body.protocol}://${RHS_HOST}:${this.bondTunnelData.body.serverPort}`
        fetch(url, {
          method: 'GET',
          mode: 'no-cors',
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          // Since no-cors mode will limit the response, you can't access response.json() or response body
          if (response) {
            this.isTunnelActive = true;
            this.tunnelStatusCheckCount = 0;
            clearInterval(this.intervalTunnelStatus);
            this.intervalTunnelStatus = null;
            this.cd.detectChanges();
          }
        }).catch(error => { });
      }
    } else {
      this.tunnelStatusCheckCount = 0;
      clearInterval(this.intervalTunnelStatus);
      this.intervalTunnelStatus = null;
      this.cd.detectChanges();
    }
  }

  // firewall
  openFirewall() {
    if (this.firewallTunnelData && this.firewallTunnelData.type == AppConst.tunnelType.firewallTunnel && this.firewallTunnelData.body.serverPort && this.firewallTunnelData.body.protocol) {
      window.open(`${this.firewallTunnelData.body.protocol}://${RHS_HOST}:${this.firewallTunnelData.body.serverPort}`, '_blank');
    }
  }

  connectFirewall() {
    if (!this.illuminateMac) {
      this.sharedService.loggerError('Unable to detect Mac Address');
      return;
    }
    let reqObject: any = {
      nodeKey: this.bondStatus?.bonder?.key,
      name: this.bondStatus?.bonder?.name,
      mac: this.illuminateMac,
    };
    this.firewallTunnelEditLoader = true;
    this.PartnerBondingService.connectFirewall(reqObject, this.bondId)?.subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.sharedService.loggerSuccess(addRes.message);
        setTimeout(() => {
          this.firewallTunnelEditLoader = false;
          this.cd.detectChanges();
        }, 30000)
        this.getInstallFirewall();
      } else {
        this.firewallTunnelEditLoader = false;
        this.sharedService.loggerError(addRes.message);
      }
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.firewallTunnelEditLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.firewallTunnelEditLoader = false;
        this.cd.detectChanges();
      }
    });
  }

  updateFirewall() {
    let reqObject = {
      tunnel_ip: this.firewallData.tunnel_ip,
      tunnel_port: this.firewallData.tunnel_port,
      tunnel_protocol: this.firewallData.tunnel_protocol,
      tunnel_expiry: this.firewallData.tunnel_expiry
    };
    this.firewallTunnelEditLoader = false;
    this.firewallTunnelDeleteLoader = true;
    this.PartnerBondingService.updateFirewall(reqObject, this.bondId)?.subscribe((addRes) => {
      if (addRes.code == 204 || addRes.code == 200) {
        this.firewallTunnelData = null;
        this.getInstallFirewall();
        this.sharedService.loggerSuccess("Firewall connection removed successfully");
      } else this.sharedService.loggerError(addRes.message);
      this.firewallTunnelDeleteLoader = false;
      this.cd.detectChanges();
    }, (err) => {
      try {
        let error = JSON.parse(err);
        if (error.non_field_errors) this.sharedService.loggerError(error.non_field_errors);
        else this.sharedService.loggerError(err);
        this.firewallTunnelDeleteLoader = false;
        this.cd.detectChanges();
      } catch (e) {
        this.sharedService.loggerError(err);
        this.firewallTunnelDeleteLoader = false;
        this.cd.detectChanges();
      }
    });
  }
}
