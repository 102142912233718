import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const MAIL_URL = environment.apiUrl + '/partnerPanel/customeEmailTemplate/';
@Injectable({ providedIn: 'root' })
export class PartnerEmailManagementService {

  constructor(private http: HttpClient) { }

  getCustomEmailTemplateListingPartner(search: string,
    page: string,
    limit: number,
    name: string,
    sort: any,
    partnerId: any
  ) {
    let params = new HttpParams()
      .set('searchText', search ? search : '')
      .set('page', page)
      .set('pageSize', limit)
      .set('name', name ? name : 'createdAt')
      .set('sort', sort ? sort : -1)
    return this.http.get<any>(MAIL_URL + `list/${partnerId}`, { params });
  }

  deleteCustomEmailTemplate(id: any, partnerId: any) {
    return this.http.delete(MAIL_URL + `delete/${id}/${partnerId}`);

  }

  createCustomEmailTemplatePartner(data: any, partnerId: any) {
    return this.http.post<any>(MAIL_URL + `add/${partnerId}`, data);
  }

  updateCustomEmailTemplatePartner(data: any, id: any, partnerId: any) {
    return this.http.put<any>(MAIL_URL + `update/${id}/${partnerId}`, data);
  }

  getCustomEmailTemplateByIdPartner(id: any, partnerId: any) {
    return this.http.get<any>(MAIL_URL + `view/${id}/${partnerId}`);
  }
}
