import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

const BACKEND_URL = environment.apiUrl + '/partnerPanel/sdwan/rhs/';

@Injectable({ providedIn: 'root' })
export class RhsService {
    partnerId = this.storageService.getCipherObj('mainId');
    tunnelListDataByBond: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(private http: HttpClient, private storageService: StorageService) { }

    get tunnelListDataByBond$() {
        return this.tunnelListDataByBond.asObservable();
    }

    getTunnelData() {
        this.partnerId = this.storageService.getCipherObj('mainId');
        return this.http.get<any>(BACKEND_URL + `/${this.partnerId}`);
    }

    tunnelListByBond(bondId: any) {
        this.partnerId = this.storageService.getCipherObj('mainId');
        return this.http.get<any>(BACKEND_URL + `tunnelListByBond/${bondId}/${this.partnerId}`);
    }

    createUpdateTunnel(data: any) {
        this.partnerId = this.storageService.getCipherObj('mainId');
        return this.http.post<any>(BACKEND_URL + `createUpdateTunnel/${this.partnerId}`, data);
    }

    deleteTunnel(data: any) {
        this.partnerId = this.storageService.getCipherObj('mainId');
        return this.http.put<any>(BACKEND_URL + `deleteTunnel/${this.partnerId}`, data);
    }

    resetRatholeToken(id: string) {
        return this.http.put(BACKEND_URL + `resetRatholeToken/${id}`, { id });
    }
}
